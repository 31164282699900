<template>
  <div class="bg-white rounded-10 p-10 pb-20 text-left">
    <div class="w-full mr-10">
      <div class="text-c16 font-c5 flex">
        <div class="w-1/5">Numero</div>
        <div class="w-1/5 ml-6 text-90">Banque</div>
        <div class="w-1/5 ml-6 text-90">Montant</div>
        <div class="w-1/5 ml-6 text-90">Tireur</div>
        <div class="w-1/5 ml-6 text-90"></div>
      </div>

      <div
          v-if="charge"
          class="flex justify-center mt-16"
      >
        <easy-spinner
            type="dots"
            size="50"
        />
      </div>

      <div class="mt-10 text-90 text-center" v-if="allData.length === 0">Aucun chèque pour le moment</div>

      <div class="mt-10" v-for="(item, index) in allData" :key="index">
        <templateCheque :donnes="item" :index="index" @info="retourValue"/>
      </div>
    </div>

    <div class="w-full pl-20 pt-10 bloc2 mt-6 flex items-center justify-end">
      <div class="w-1/3">
        <div class="text-c12 text-98">Nombre de chèques <span class="text-black">: {{ allSelect.length }}/{{ allData.length }}</span></div>

        <div class="text-c12 text-98 mt-4">Montant total <span class="text-black">:
          <span v-if="!specialStat">{{ wallet.amount.toLocaleString() }}</span>
            <span v-if="specialStat">{{ specialStat.checkAmount.toLocaleString() }}</span>
          F</span></div>
      </div>
      <div class="w-1/3">
        <div class="text-c16 text-90">
          Montant depose
        </div>

        <div class="text-c24 font-c6 mt-2">
          {{ totalAmount.toLocaleString() }} <span class="text-90">FCFA</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import templateCheque from './templateCheque'

export default {
  name: "Index",
  components: {
    templateCheque
  },
  props: {
    specialStat: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      allData: [],
      forget: false,
      wallet: null,
      allSelect: [],
      charge: true
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    totalAmount: function () {
      if (this.allSelect.length > 0) {
        let total = 0
        for (let item in this.allSelect) {
          total = total + this.allSelect[item].amount
        }
        return total
      } else {
        return 0
      }
    }
  },

  created () {
    this.wallet = this.$store.getters.trackTemporal
    this.getCheque()
  },

  methods: {
    retourIgnore (answer) {
      this.forget = answer
    },

    retourValue (answer) {
      if (answer.active) {
        this.allSelect.push(this.allData[answer.index])
      } else {
        for (let item in this.allSelect) {
          if (this.allSelect[item].id === this.allData[answer.index].id) {
            this.allSelect.splice(item, 1)
          }
        }
      }
      this.sender()
    },

    sender () {
      if (!this.forget){
        this.$emit('info', this.allSelect)
      }
    },

    getCheque() {
      http.get(apiroutes.baseURL + apiroutes.allDataDeposit + '?type=BANK_CHECK')
          .then(response => {
            console.log('success')
            console.log(response)
            this.allData = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.bloc2 {
  border-top: 0.352891px solid #DDDDDD;
}
.inputo {
  background: #F5F5F5;
  border: 0.956973px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4.78486px;
  height: 36.36px;
}
</style>
