<template>
  <div class="p-10 pr-16 all">
    <popupCharge :activation="charge" v-if="charge"/>
    <success v-if="activeSuccess" :pdf="pdf" :message="message" :activation="activeSuccess"  @oga="retourSuccess"/>
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-4/5 flex text-left text-c36 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
            @click="retour"
          />
          <div class="ml-4 w-3/5">
            Remise en banque
          </div>
        </div>

        <div class="w-1/5">
          <bouton v-if="position === 1 && banque === null" label="Suivant" height="54.4px" background="#DDDDDD"/>
          <div v-if="position >= 1 && banque !== null">
            <bouton v-if="position < 3" label="Suivant" height="54.4px" @info="continuer"/>
            <bouton v-if="position === 3" label="Enregistrer" height="54.4px" @info="continuer" />
          </div>
        </div>
      </div>

      <div class="mt-10 pl-4">
        <div class="flex mt-6 justify-center bg-white rounded-10 p-6 pl-10 pr-10">
          <div class="w-1/2" @click="selected(1)">
            <steep-line :all-steep="allSteep" :position="position" :index="0"/>
          </div>
          <div class="w-1/2 " @click="selected(2)">
            <steep-line :all-steep="allSteep" :position="position" :index="1"/>
          </div>
          <div class="w-auto" @click="selected(3)">
            <steep-line :all-steep="allSteep" :position="position" :index="2"/>
          </div>
        </div>
      </div>

      <div class="mt-10 pl-4">
        <banque v-if="position === 1" @info="retourBanque"/>

        <div v-if="position === 2">
          <espece v-if="banque.type === 'Espèce'" :specialStat="specialStat" @info="retourBilleterie"/>
          <cheque v-if="banque.type === 'Chèque'" :specialStat="specialStat" @info="retourCheque"/>
        </div>

        <recap v-if="position === 3" :total="totalRemise" :specialStat="specialStat" :type="banque.type" @info="retourRecap"/>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bouton from '../../component/helper/add/button'
import steepLine from '../../component/helper/add/steepLine'
import recap from '../../component/cdevs/bankRemise/recap'
import espece from '../../component/cdevs/bankRemise/espece'
import cheque from '../../component/cdevs/bankRemise/cheque'
import banque from '../../component/cdevs/bankRemise/banque'
import success from '../../component/popup/success'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'
import popupCharge from '../../component/popup/popupCharge'

export default {
  name: "Index",

  components: {
    bouton,
    steepLine,
    espece,
    cheque,
    banque,
    success,
    recap,
    popupCharge
  },

  data () {
    return {
      icons: {
        back,
      },
      allSteep: ['BANQUE', '-', 'VALIDATION'],
      position: 1,
      activeSuccess: false,
      message: 'Remise en banque enregistrée avec succès',
      billeterie: null,
      cheques: null,
      banque: null,
      totalRemise: 0,
      recap: null,
      charge: false,
      pdf: null,
      wallet: null,
      specialStat: null
    }
  },

  created () {
    this.wallet = this.$store.getters.trackTemporal
    if (this.wallet.department || !this.wallet.bankName) {
      this.specialStat = this.wallet.stats
    }
    console.log('wallet')
    console.log(this.wallet)
  },

  methods: {
    selected (index) {
      this.position = index
    },

    retourBanque (answer) {
      this.banque = answer
      if (answer.type !== undefined) {
        this.allSteep[1] = answer.type
      }
    },

    retourBilleterie (answer) {
      this.billeterie = answer
      if (answer.length > 0) {
        let total = 0
        for (let item in answer) {
          total = total + (answer[item].count * answer[item].amount)
        }
        this.totalRemise = total
      } else {
        this.totalRemise = 0
      }
    },

    retourCheque (answer) {
      this.cheques = answer

      if (answer.length > 0) {
        let total = 0
        for (let item in answer) {
          total = total + answer[item].amount
        }
        this.totalRemise = total
      } else {
        this.totalRemise = 0
      }
    },

    retourRecap (answer) {
      this.recap = answer
    },

    continuer () {
      if (this.position < 3) {
        this.position = this.position + 1
      } else {
       this.save()
      }
    },

    save() {
      if (this.recap.file) {
        this.saveFile(this.recap.file)
      } else {
        alert('Aucune preuve')
        this.position = 3
      }
    },

    //Ici j'enregistre les fichiers et je récupère des url
     saveFile (file) {
      this.charge = true
      let data = new FormData()
      data.append('files', file)
      let config = {
        header : {
          'Content-Type' : 'multipart/form-data'
        }
      }

      http.post(apiroutes.baseURL + apiroutes.upload, data, config)
          .then(response => {
            this.finaly(response[0].url)
          })
          .catch(error => {
            console.log(error)
          })
     },

    finaly (url) {
      const obj = {
        type: "CASH",
        depositNumber: this.banque.numero,
        date: this.banque.date,
        agency_wallet: this.wallet.id,
        bank: this.banque.bank,
        data: [
          {
            "count": 2,
            "amount": 10000
          }
        ],
        proof: url,
        note: this.recap.note,
        meta: {
          file: url,
          note: this.recap.note
        }
      }

      if (this.banque.type === 'Chèque') {
        obj.type = 'BANK_CHECK'
        let tab = []
        for (let item in this.cheques) {
          tab.push(this.cheques[item].id)
        }

        obj.data = tab
      }
      if (this.banque.type === 'Espèce') {
        obj.type = 'CASH'
        obj.data = this.billeterie
      }

      http.post(apiroutes.baseURL + apiroutes.remise, obj)
          .then(response => {
            console.log(response)
            this.pdf = response.pdf
            this.activeSuccess = true
          })
          .catch(error => {
            console.log(error)
          })
    },

    retour () {
      window.history.back()
    },
    retourSuccess () {
      this.$router.push('/banques')
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.all{
  min-height: 100vh;
}

</style>
